export const currencySymbol: { [key: string]: any } = {
  INR: "₹", // Indian Rupee
  BDT: "BDT", // Bangladeshi Taka
  AED: "AED", // United Arab Emirates Dirham
  BHD: "BHD", // Bahraini Dinar
  KWD: "KWD", // Kuwaiti Dinar
  OMR: "OMR", // Omani Rial
  QAR: "QAR", // Qatari Riyal
  SAR: "SAR", // Saudi Riyal
}

export const gstDetails = {
  gstPercentage: 18,
}
