import { TeacherSchema } from "Interfaces/reducerTypes/TeacherSchema"
import { checkIfMENAUser, getUserCountry } from "utils/country_utils"
import { navRoutes } from "page_routes"
import { gstDetails } from "DataMapper/IeltsSection/currencyMapper"

export const convertFacultyData = (
  facultyData: TeacherSchema[],
): TeacherSchema[] => {
  const visibleOnHomePage = facultyData.filter(
    (data: TeacherSchema) => data.visibleOnHomePage,
  )
  const dataInreqFormat = visibleOnHomePage.reduce((a: any, c: any) => {
    const newObj = JSON.parse(JSON.stringify(c))
    newObj.headerText = `${newObj.yearsOfExperience}+ years of experience`
    newObj.bodyText = newObj.description
    newObj.footerText = newObj.shortDescription
    newObj.image = newObj.mobileImage
    a.push(newObj)
    return a
  }, [])
  return dataInreqFormat.reverse()
}

export const getIeltsRefPath = (userCountry: string | null) => {
  return checkIfMENAUser(userCountry)
    ? navRoutes.MEA_IELTS_STUDENT_DASHBOARD
    : navRoutes.IELTS_STUDENT_DASHBOARD
}

export const getObjectKeys = Object.keys as <T extends object>(
  obj: T,
) => Array<keyof T>

export const scrollToDivSmoothly = (divRef: any) => {
  divRef.current?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  })
}

export enum FORM_ID {
  IELTS_INDIA_REFERAL_FORM = "IELTS India Referral Form",
}

export const getGstPrice = (
  payablePrice: number,
): { displayPrice: number; gstAmount: number } => {
  const gstAmount = Math.round(
    Number(
      (
        (payablePrice * (gstDetails.gstPercentage / 100)) /
        (1 + gstDetails.gstPercentage / 100)
      ).toFixed(2),
    ),
  )
  return {
    displayPrice: Number((payablePrice - gstAmount).toFixed(2)),
    gstAmount,
  }
}

export const getLocalStringNumber = (number?: string | number | null) =>
  number ? number.toLocaleString() : number
